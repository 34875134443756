import classnames from 'classnames';
import React, { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode, useMemo } from 'react';
import styles from './Button.module.css';
import Link from 'next/link';
import { HeroIcon } from 'components/HeroIcon';
import { IconType } from 'components/helpers/types';

type Theme = 'primary' | 'secondary' | 'discrete' | 'plain' | 'cta';

export const isButton = (button: ButtonProperties | ButtonLinkProperties): button is ButtonProperties =>
  (button as ButtonProperties)?.onClick !== undefined;

export interface ButtonProperties
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  discrete?: boolean;
  secondary?: boolean;
  theme?: Theme;
  iconLeft?: IconType;
  icon?: IconType;
}

export const Button = ({ theme = 'primary', children, type = 'button', icon, iconLeft, ...rest }: ButtonProperties) => {
  const buttonClassName = useMemo(() => getButtonClassnames(theme, children), [theme, children]);

  return (
    <button
      type={type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'}
      className={buttonClassName}
      {...rest}>
      {!!iconLeft && <HeroIcon icon={iconLeft} />}
      <span>{children}</span>
      {!!icon && <HeroIcon icon={icon} />}
    </button>
  );
};

export interface ButtonLinkProperties
  extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref'> {
  theme: Theme;
  href: string;
  iconLeft?: IconType;
  icon?: IconType;
}

export const ButtonLink = ({ theme = 'primary', children, icon, iconLeft, ...rest }: ButtonLinkProperties) => {
  const buttonClassName = useMemo(() => getButtonClassnames(theme, children), [theme, children]);

  return (
    <Link className={buttonClassName} {...rest}>
      {!!iconLeft && <HeroIcon icon={iconLeft} />}
      <span>{children}</span>
      {!!icon && <HeroIcon icon={icon} />}
    </Link>
  );
};

function getButtonClassnames(theme: Theme, children?: ReactNode) {
  return classnames(styles.button, {
    [styles.primary]: theme === 'primary',
    [styles.secondary]: theme === 'secondary',
    [styles.discrete]: theme === 'discrete',
    [styles.plain]: theme === 'plain',
    [styles.cta]: theme === 'cta',
    [styles.noChildren]: !children,
  });
}
