import { FormEventHandler, ReactNode } from 'react';
import styles from './Form.module.css';
import { Loader } from 'components/Loader';
import { Button } from 'components/Button';

interface Properties {
  children: ReactNode;
  error?: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  isSubmitting?: boolean;
  buttonLabel?: string;
  hideButton?: boolean;
}

export const Form = ({
  children,
  error,
  onSubmit,
  isSubmitting,
  buttonLabel = 'Verzenden',
  hideButton = false,
}: Properties) => (
  <form className={styles.form} onSubmit={onSubmit}>
    {children}
    {!!error && <div className={styles.error}>{error}</div>}
    {!!isSubmitting && <Loader />}
    {!hideButton && (
      <Button disabled={isSubmitting} type="submit">
        {buttonLabel}
      </Button>
    )}
  </form>
);
