import React, { useMemo } from 'react';
import styles from './PassedFileTerm.module.css';
import { RichText } from 'components/RichText';

export const PassedFileTerm = () => {
  // from april 8th, the term to file is passed
  const isPassedApril8 = useMemo(() => {
    const today = new Date();
    const april8 = new Date(today.getFullYear(), 3, 8);
    return today > april8;
  }, []);

  if (!isPassedApril8) return;
  return (
    <div className={styles.container}>
      <RichText center>
        <h2>Let op de bezwaartermijn</h2>
        <p>
          Vanaf de dagtekening op je WOZ-aanslag van de gemeente heb je 6 weken om bezwaar te maken. Als je bij ons een
          bezwaar indient na deze 6 weken zal deze afgekeurd worden door de gemeente. Hou de termijn dus goed in de
          gaten!
        </p>
      </RichText>
    </div>
  );
};
