import { Usps } from 'components/Usps';
import styles from './Result.module.css';
import { Faq } from 'components/Faq';
import { Support } from 'components/Support';
import { Cta } from 'components/Cta';
import { KlantenVertellen } from 'components/KlantenVertellen';
import { Button, ButtonLink } from 'components/Button';
import { useSteps } from 'context/StepsProvider';
import { RichText } from 'components/RichText';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { getWozResult } from 'components/helpers/cookies';
import { WozRange } from 'components/WozRange';
import { NewsLetterSubscriptionPopup } from 'components/NewsLetterSubscriptionPopup';
import { Savings } from 'components/Savings';
import { AddressHasNoLivingPurpose } from 'components/AddressHasNoLivingPurpose';
import { AnimatePresence, motion } from 'framer-motion';
import { PassedFileTerm } from 'components/PassedFileTerm';
import Link from 'next/link';

export const Result = () => {
  const [showNewsLetterPopup, setShowNewsLetterPopup] = useState(false);
  const [showCtaReference, setShowCtaReference] = useState(false);
  const wozResult = useMemo(() => getWozResult(), []);

  const { handleNextStep, resetScan } = useSteps();

  const hasLivingPurpose = useMemo(
    () =>
      wozResult?.address?.purposes.some(
        (purpose) => purpose.toLowerCase() === 'woonfunctie' || purpose.toLowerCase() === 'logiesfunctie'
      ),
    [wozResult]
  );

  const wozRangeReference = useRef<HTMLDivElement>(null);
  const ctaReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: undefined,
      rootMargin: '0px',
      threshold: 0.7,
    };

    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === wozRangeReference.current) {
          setShowCtaReference(!entry.isIntersecting);
        }
      }
    }, options);

    if (wozRangeReference.current) {
      observer.observe(wozRangeReference.current);
    }

    return () => {
      if (wozRangeReference.current) {
        observer.unobserve(wozRangeReference.current);
      }
    };
  }, []);

  const nextStepUnknown = useMemo(
    () =>
      wozResult?.range.nextStep === 'unknown_woz_value' ||
      (Array.isArray(wozResult?.range) && wozResult?.range.length === 0),
    [wozResult?.range]
  );

  return (
    <div className={styles.container}>
      {wozResult?.range?.nextStep === 'yes' && hasLivingPurpose ? (
        <Fragment>
          <RichText center>
            <h1>Jouw WOZ klopt niet, besparing mogelijk!</h1>
          </RichText>
          {!!wozResult?.range && <WozRange ref={wozRangeReference} wozResult={wozResult?.range} />}
          <PassedFileTerm />
          {!!wozResult?.range.savings && <Savings savings={wozResult?.range.savings} />}
        </Fragment>
      ) : undefined}
      {wozResult?.range?.nextStep === 'no' && !!hasLivingPurpose && (
        <Fragment>
          <RichText center>
            <h1>Jouw WOZ-waarde klopt. Je kunt dit jaar via ons geen bezwaar starten.</h1>
          </RichText>
          <Cta
            title="Volgend jaar direct weten of je bezwaar kunt maken?"
            description="Met onze WOZ-wekker laten we volgend jaar via een mailtje weten of je WOZ-waarde klopt."
            button={{
              children: 'Aanmelden',
              href: 'https://juistwoz.nl/woz-wekker/',
              iconLeft: 'BellAlertIcon',
              theme: 'cta',
            }}
          />
        </Fragment>
      )}
      {!!nextStepUnknown && (
        <div className={styles.notFound}>
          <RichText center>
            <h2>De juiste WOZ-waarde kan nog niet berekend worden</h2>
            <p>
              Je gemeente heeft de WOZ-waarde van 2024 nog niet bekend gemaakt. Laat je gegevens achter en we sturen een
              e-mail wanneer we jouw juiste WOZ-waarde kunnen bepalen. Het kan ook zijn dat de scan je WOZ-waarde niet
              op kan halen. Heb je een aanslagbiljet met WOZ-waarde ontvangen en wil je bezwaar maken? Mail dan een
              kopie of foto van het aanslagbiljet naar{' '}
              <Link href="mailto:maakbezwaar@juistwoz.nl">maakbezwaar@juistwoz.nl</Link> en wij gaan voor jou aan de
              slag!
            </p>
          </RichText>

          <div className={styles.buttonContainer}>
            <Button onClick={resetScan} theme="secondary">
              Start opnieuw
            </Button>
            <ButtonLink href="https://juistwoz.nl/woz-wekker/" theme="cta">
              Gegevens achterlaten
            </ButtonLink>
          </div>
          <p className={styles.disclaimer}>
            Onze werkzaamheden worden <strong>100% vergoed</strong> door de gemeente
          </p>
        </div>
      )}
      {!hasLivingPurpose && <AddressHasNoLivingPurpose />}
      {wozResult?.range.nextStep !== 'yes' && <Faq nextStep={false} />}
      <Usps />
      <KlantenVertellen />
      {wozResult?.range.nextStep === 'yes' && <Faq />}
      {wozResult?.range.nextStep === 'yes' && <Support />}
      <Cta
        title="Volgend jaar direct weten of je bezwaar kunt maken?"
        description="Met onze WOZ-wekker laten we volgend jaar via een mailtje weten of je WOZ-waarde klopt."
        button={{
          children: 'Aanmelden',
          href: 'https://juistwoz.nl/woz-wekker/',
          iconLeft: 'BellAlertIcon',
          theme: 'cta',
        }}
      />
      {wozResult?.range.nextStep === 'yes' && <KlantenVertellen />}
      <AnimatePresence>
        {wozResult?.range?.nextStep === 'yes' && !!showCtaReference && (
          <motion.div
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={styles.confirmationButtonContainer}
            ref={ctaReference}>
            <RichText>
              <p>Let op! Je betaalt mogelijk teveel belasting</p>
            </RichText>
            <Button onClick={handleNextStep}>Maak gratis bezwaar</Button>
          </motion.div>
        )}
      </AnimatePresence>
      {!!showNewsLetterPopup && !!wozResult?.uuid && (
        <NewsLetterSubscriptionPopup intakeUuid={wozResult?.uuid} onClose={() => setShowNewsLetterPopup(false)} />
      )}
    </div>
  );
};
