import { InputContainer, InputContainerProperties } from './InputContainer';
import React, { SelectHTMLAttributes, forwardRef, useMemo } from 'react';
import styles from './Select.module.css';

interface Properties extends Omit<InputContainerProperties, 'children'>, SelectHTMLAttributes<HTMLSelectElement> {
  options?: { label: string; value: string | number }[];
}

export const Select = forwardRef<HTMLSelectElement, Properties>(
  ({ description, disabled, error, label, options = [], required, ...rest }, reference) => {
    const classNames = useMemo(() => (error ? `${styles.select} ${styles.error}` : styles.select), [error]);

    return (
      <InputContainer description={description} error={error} label={label} required={required}>
        <div className={styles.selectContainer}>
          <select defaultValue={undefined} className={classNames} disabled={disabled} ref={reference} {...rest}>
            <option />
            {options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </InputContainer>
    );
  }
);

Select.displayName = 'Select';
