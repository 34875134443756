export function scrollToStep(newStepIndex: number) {
  const element = document.querySelector('[data-index="' + newStepIndex + '"]');

  if (element) {
    setTimeout(() => {
      element.scrollIntoView({
        inline: 'start',
        behavior: 'smooth',
      });

      window.scrollTo(0, 0);
    }, 1); // the delay is needed for Firefox
  }
}
