import { Cta } from 'components/Cta';
import { RichText } from 'components/RichText';
import styles from './AddressHasNoLivingPurpose.module.css';

export const AddressHasNoLivingPurpose = ({
  h1 = 'Je hebt een adres ingevoerd waar geen woonbestemming op zit',
  h2 = 'Wat houdt dit in?',
}: {
  h1?: string;
  h2?: string;
}) => (
  <div className={styles.container}>
    <RichText center>
      <div className={styles.businessTextContainer}>
        <h1>{h1}</h1>
        <h2>{h2}</h2>
      </div>
    </RichText>
    <RichText>
      <p>
        Omdat er op dit adres geen woonbestemming zit, kan JUIST je bezwaar niet afhandelen. Ons zusterbedrijf Previcus
        is hierin wel gespecialiseerd. Om je bezwaar door hen te laten starten is er een startfee vooraf van € 295 excl.
        BTW. De overige werkzaamheden tijdens het bezwaar worden wel 100% vergoed door de gemeente.
      </p>
    </RichText>
    <Cta
      plain
      button={{
        children: 'Vind meer informatie bij Previcus',
        href: 'https://www.previcus.nl/',
        theme: 'cta',
      }}
    />
  </div>
);
