import { FieldError, FormState } from 'react-hook-form';

export function getErrorFromFormState(formState: FormState<any>, fieldName: string) {
  const fieldnamePath = fieldName.split('.');

  if (!fieldnamePath || fieldnamePath.length === 0) return;
  let errorObject: Record<string, unknown> = formState.errors;
  for (const element of fieldnamePath) {
    if (Object.keys(errorObject).length === 0) return;
    errorObject = (errorObject?.[element] as FieldError) ?? errorObject;
  }

  return errorObject?.message?.toString();
}
