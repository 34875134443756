import { AllFields } from 'components/helpers/types';
import { FieldErrors } from 'react-hook-form';

export function hasCurrentStepErrors(currentStepFields: string[], errors?: FieldErrors<AllFields>) {
  if (!errors) return false;
  for (const field of currentStepFields) {
    // Split the field path into individual keys
    const keys = field.split('.');

    // Reference the errors object using the keys
    let currentObject: unknown = errors;

    for (const key of keys) {
      if (Object.prototype.hasOwnProperty.call(currentObject, key)) {
        currentObject = currentObject?.[key as keyof typeof currentObject] || {};
      } else {
        break;
      }
    }

    // Check if there is an error for the field
    if (currentObject && Object.prototype.hasOwnProperty.call(currentObject, 'message')) {
      return true;
    }
  }
  return false;
}
