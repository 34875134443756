import styles from './Cta.module.css';
import { RichText } from 'components/RichText';
import { Button, ButtonLink, ButtonLinkProperties, ButtonProperties, isButton } from 'components/Button';
import cx from 'classnames';

interface Properties {
  title?: string;
  description?: string;
  button: ButtonLinkProperties | ButtonProperties;
  plain?: boolean;
  bottomText?: string;
}

export const Cta = ({ plain, title, description, button, bottomText }: Properties) => (
  <div className={cx(styles.container, { [styles.plain]: plain, [styles.hasBottomText]: !!bottomText })}>
    {(!!title || !!description) && (
      <RichText>
        <h2>{title}</h2>
        <p>{description}</p>
      </RichText>
    )}
    {!isButton(button) && <ButtonLink {...button} />}
    {!!isButton(button) && <Button {...button} />}
    {!!bottomText && (
      <RichText center>
        <p className={styles.bottomText}>{bottomText}</p>
      </RichText>
    )}
  </div>
);
