import { getProgress } from 'components/helpers/progress';
import { AllFields } from 'components/helpers/types';

export function nextStepDisabled({
  allValues,
  currentStepIndex,
  currentSubStepIndex,
  calculationSucceeded,
}: {
  allValues?: AllFields;
  currentStepIndex?: number;
  currentSubStepIndex?: number;
  calculationSucceeded?: boolean;
} = {}) {
  const progress = getProgress();

  if (!allValues || currentStepIndex === undefined || currentSubStepIndex === undefined || !progress) {
    return true;
  }

  switch (currentStepIndex) {
    case 0: {
      if (!allValues?.addressDetails?.address) {
        return true;
      }
      break;
    }
    case 1: {
      const livingSituation = allValues?.livingSituation;
      if (currentSubStepIndex === 0) {
        return livingSituationFirstStepDisabled(livingSituation);
      }
      return !livingSituation.houseType;
    }

    case 2: {
      const charactaristics = allValues?.charactaristics;
      return !charactaristics?.useSurface || !charactaristics?.bathroomAge || !charactaristics?.kitchenAge;
    }

    case 3: {
      if (currentSubStepIndex === 0) {
        return !allValues?.quality?.houseDurability;
      }
      return !allValues?.quality?.houseMaintenanceLevel;
    }

    case 4: {
      return !calculationSucceeded;
    }

    default: {
      return false;
    }
  }

  return false;
}

function livingSituationFirstStepDisabled(livingSituation: AllFields['livingSituation']) {
  if (livingSituation?.relationshipToProperty === 'landlord') {
    return !livingSituation?.relationshipToProperty;
  } else if (livingSituation?.isTransferredAfter2020 === 'yes') {
    return (
      !livingSituation?.relationshipToProperty ||
      !livingSituation?.isTransferredAfter2020 ||
      !livingSituation?.isTransferredAfter2020 ||
      !livingSituation?.transferDate
    );
  } else {
    return !livingSituation?.relationshipToProperty || !livingSituation?.isTransferredAfter2020;
  }
}
