import { useFormContext } from 'react-hook-form';
import { RadioGroup } from 'components/RadioGroup';
import { STEPS } from 'components/constants/STEPS';
import { getErrorFromFormState } from 'components/helpers/getErrorFromFormState';

const OPTIONS = [
  {
    label: '2-onder-1-kapwoning',
    value: '2-onder-1-kapwoning',
  },
  {
    label: 'Tussenwoning',
    value: 'tussenwoning',
  },
  {
    label: 'Hoekwoning',
    value: 'hoekwoning',
  },
  {
    label: 'Vrijstaande woning',
    value: 'vrijstaande woning',
  },
  {
    label: 'Bungalow',
    value: 'bungalow',
  },
  {
    label: 'Appartement met tuin',
    value: 'appartement met tuin',
  },
  {
    label: 'Appartement',
    value: 'appartement',
  },
];

export const HouseType = () => {
  const { setValue, watch, formState } = useFormContext<any>();

  const houseTypeFieldName = STEPS[1].subSteps[1].fields[0];

  return (
    <RadioGroup
      error={getErrorFromFormState(formState, houseTypeFieldName)}
      name={houseTypeFieldName}
      options={OPTIONS}
      label="Welk woningtype is jouw woning?"
      value={watch(houseTypeFieldName)}
      onChange={(event) => setValue(houseTypeFieldName, event.target.value)}
    />
  );
};
