import React from 'react';
import styles from './Support.module.css';
import Image from 'next/image';
import { RichText } from 'components/RichText';
import { ButtonLink } from 'components/Button';
import { PHONE_NUMBER_WIHTOUT_DASHES } from 'components/constants/PHONE_NUMBER';

export const Support = () => (
  <div className={styles.container}>
    <Image src="/previcus-team.webp" width={500} height={500} alt="previcus-team" />
    <div className={styles.contactContainer}>
      <RichText>
        <h3>Kom je er niet helemaal uit?</h3>
        <p>Onze adviseurs helpen je graag verder op werkdagen van 8:30 tot 22:00.</p>
      </RichText>
      <div className={styles.buttonContainer}>
        <ButtonLink theme="primary" href={`tel:${PHONE_NUMBER_WIHTOUT_DASHES}`} iconLeft="PhoneIcon">
          Bellen
        </ButtonLink>
        <ButtonLink theme="secondary" href="mailto:info@previcus.nl" iconLeft="EnvelopeOpenIcon">
          Mailen
        </ButtonLink>
      </div>
    </div>
  </div>
);
