import { InputContainer, InputContainerProperties } from 'components/InputContainer';
import React, { ChangeEventHandler, forwardRef } from 'react';
import styles from './RadioGroup.module.css';
import cx from 'classnames';

interface Properties extends Omit<InputContainerProperties, 'children'> {
  disabled?: boolean;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  error?: string;
  options?: { disabled?: boolean; label: string; value: string; icon?: () => JSX.Element }[];
}

export const RadioGroup = forwardRef<HTMLInputElement, Properties>(
  ({ error, label, name, options, value, required, onChange }, reference) => (
    <InputContainer label={label} error={error} required={required}>
      <div className={cx(styles.radioContainer, { [styles.error]: !!error })}>
        {options?.map((option) => {
          const htmlForId = `${option.value}-radio`;
          const Icon = option.icon;
          return (
            <label
              className={cx(styles.inputContainer, { [styles.checked]: value === option.value })}
              key={option.label}
              htmlFor={htmlForId}>
              <CheckMark />
              <input
                type="radio"
                ref={reference}
                name={name}
                onChange={onChange}
                checked={value === option.value}
                id={htmlForId}
                {...option}
              />
              {option.label}
              {!!Icon && (
                <div className={styles.iconContainer}>
                  <Icon />
                </div>
              )}
            </label>
          );
        })}
      </div>
    </InputContainer>
  )
);

const CheckMark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12">
    <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6666" d="M10 3 4.5 8.5 2 6" />
  </svg>
);

RadioGroup.displayName = 'RadioGroup';
