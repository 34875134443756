import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useEffect, useRef } from 'react';
import styles from './Popup.module.css';
import cx from 'classnames';
import { Button } from 'components/Button';

const TRANSITION = { duration: 0.2 };
const VARIANTS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const FOCUS_ELEMENTS_QUERY =
  'a[href], button:not([disabled]), details, input:not([readonly]), select, textarea, [tabindex]:not([tabindex^="-"])';

const trapFocusInsideModal = (event: KeyboardEvent, element: HTMLElement | undefined | null) => {
  if (event.key !== 'Tab') return;

  const focusableModalElements = element?.querySelectorAll<HTMLElement>(FOCUS_ELEMENTS_QUERY);

  if (focusableModalElements && focusableModalElements?.length > 0) {
    const firstElement = focusableModalElements[0];
    // eslint-disable-next-line unicorn/prefer-at
    const lastElement = focusableModalElements[focusableModalElements.length - 1];

    // if going forward by pressing tab and lastElement is active shift focus to first focusable element
    if (!event.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      event.preventDefault();
    }

    if (event.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      event.preventDefault();
    }
  }
};

export interface PopupProperties {
  onClose?: () => void;
  children: ReactNode;
}

export const Popup = ({ onClose, children }: PopupProperties) => {
  const contentReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.overflow = 'hidden';
    }

    return () => {
      if (body) {
        body.style.overflow = '';
      }
    };
  }, []);

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (onClose && event.key === 'Escape') {
        onClose();
      }

      if (event.key === 'Tab') {
        trapFocusInsideModal(event, contentReference?.current);
      }
    }

    document.addEventListener('keydown', onKeyDown, false);

    return () => document.removeEventListener('keydown', onKeyDown, false);
  }, [onClose]);

  useEffect(() => {
    const firstElementInContent = contentReference.current?.querySelector<HTMLElement>(FOCUS_ELEMENTS_QUERY);

    if (firstElementInContent) {
      firstElementInContent.focus();
    }
  }, []);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        transition={TRANSITION}
        variants={VARIANTS}
        style={{
          zIndex: 'var(--z-index-overlay)',
        }}>
        <div className={styles.container} ref={contentReference}>
          <div
            className={cx(styles.popUpOverlay, { [styles.noOnClose]: !onClose })}
            onClick={onClose}
            aria-hidden="true"
          />
          <div className={styles.popUpContainer}>
            {!!onClose && <Button theme="plain" icon="XMarkIcon" onClick={onClose} />}
            {children}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
