/* eslint-disable unicorn/prefer-string-replace-all */
import React, { useCallback, useMemo, useState } from 'react';
import styles from 'components/Faq.module.css';
import cx from 'classnames';
import { HeroIcon } from 'components/HeroIcon';
import { RichText } from 'components/RichText';

const NEXT_STEP_ITEMS = [
  {
    question: '100% vergoed, het is voor mij dus gratis?',
    answer:
      'In principe is je bezwaar uit laten voeren door JUIST gratis. Echter is er vanaf januari 2024 een nieuwe wet ingegaan die de gemeente verplicht om de vergoeding naar onze klant over te maken, in plaats van rechtstreeks naar ons. Je ontvangt dus van de gemeente onze vergoeding en wij sturen je een factuur, waardoor je het weer naar ons over kunt maken. De hoogte van onze factuur is nooit meer dan het bedrag wat je van de gemeente hebt ontvangen. Daarom zeggen we dat onze werkzaamheden 100% vergoed worden, maar ‘gratis’ zou de verkeerde verwachting scheppen. Helaas onnodig omslachtig, maar als de overheid het zo wil dan doen we dat.',
  },
  {
    question: 'Wordt het bezwaar voor mijn woningsituatie op maat gemaakt?',
    answer:
      'Ja, bij elk bezwaar wordt gekeken naar de individuele aspecten die je woning uniek maken. Hoe meer we het bezwaar namelijk afstemmen op jouw situatie, hoe beter de gemeente begrijpt waarom we in bezwaar gaan. We verzamelen meer dan 100 datapunten en combineren dit met wat je ons kunt vertellen over de woning. Onze kennis en ervaring, de data die we verzamelen en jouw foto’s en informatie zorgen voor een bezwaar met de hoogste kans van slagen.',
  },
  {
    question: 'Is de kans van slagen groter als ik via een bureau bezwaar maak?',
    answer:
      'Volgens cijfers van de gemeente is die kans net zo groot. Echter blijft ook na vragen onduidelijk hoe de gemeente tot deze cijfers komt. Wel onderzocht is dat de gemiddelde verlaging groter is als je via een WOZ-bureau zoals JUIST bezwaar maakt, door onze ervaring en kennis van de wet WOZ. Zo komen we met argumenten waar burgers vaak zelf niet aan denken, zoals het controleren van afnemend grensnut en andere waardeverlagende factoren.',
  },
  {
    question: 'Wat zijn de volgende stappen na bezwaar maken?',
    answer: `Nadat je bezwaar hebt gemaakt en de machtiging is getekend gaan we het volgende doen:\n
  1. We dienen namens jou een bezwaarschrift in bij je gemeente\n
  2. Zij sturen ons aanvullende stukken en het taxatieverslag\n
  3. We vragen je om foto’s en aanvullende informatie\n
  4. Als we alle informatie hebben stellen we een woningwaarderapport op\n
  5. Namens jou doen we een hoorzitting met de gemeente\n
  6. Daarna is het wachten op de gemeente op een uitspraak\n
  7. We beoordelen die uitspraak en gaan eventueel voor je in beroep\n
  8. Als de uitspraak gegrond is ontvang je de teveel betaalde belasting terug van je gemeente`,
  },
];

const NO_NEXT_STEP_ITEMS = [
  {
    question: 'Waarom heeft bezwaar maken geen zin?',
    answer:
      'Wij hebben onze WOZ-scan ontwikkeld om een verwachting te geven of het zin heeft om in bezwaar te gaan. Hierin zit meer dan 20 jaar WOZ-ervaring gecombineerd met meer dan 100 datapunten. Die zetten we af tegen je WOZ-waarde en die van soortgelijke panden in de buurt. Als daaruit blijkt dat je WOZ-waarde niet te hoog is, dan kun je via JUIST geen bezwaar maken. Dit doen we zodat zowel ons team als de gemeente geen uren bezig is met een bezwaar waarvan we vorig al denken dat het geen zin heeft.',
  },
  {
    question: 'Kan ik zelf nog wel bezwaar maken via de gemeente?',
    answer:
      'Uiteraard! De WOZ-scan geeft alleen een verwachting van de uitkomst. Als die negatief is, is de kans klein dat je WOZ-waarde wordt verlaagd. Het kan echter altijd zijn dat er bijzondere omstandigheden zijn. Die kun je dan met de gemeente bespreken. Zij kunnen je ook toelichten hoe zij tot je waarde zijn gekomen.',
  },
  {
    question: 'Waarom nemen jullie niet elk bezwaar in behandeling?',
    answer:
      'In het voeren van een goed bezwaar zit veel tijd. Zowel bij ons, als aan de kant van de gemeente. Dit doen we graag, want wij vinden dat iedereen heeft op een kloppende WOZ-waarde. Als uit onze WOZ-scan vooraf echter blijkt dat de kans van slagen bijzonder klein is, dan vinden we het niet verstandig om de gemeente daarvoor toch aan het werk te zetten. Het staat natuurlijk altijd vrij om zelf contact op te nemen met de gemeente.',
  },
  {
    question: 'Hoe weet ik of bezwaar maken volgend jaar wel zin heeft?',
    answer:
      'We raden aan om elk jaar als de brief met de WOZ-waarde op de mat valt onze WOZ-scan in te vullen. Doordat de huizenprijzen continu veranderen, kan het zijn dat het volgend jaar namelijk wel zinvol is om bezwaar te maken. Wil je van ons een berichtje als je WOZ-waarde bekend is en je bezwaar kunt maken? Vul dan onze WOZ-wekker in op <https://juistwoz.nl/woz-wekker/>.',
  },
];

interface QA {
  question: string;
  answer: string;
}

interface FaqItemProperties {
  item: QA;
  active: boolean;
  onClick: (question: string) => void;
}

export const Faq = ({ nextStep = true }: { nextStep?: boolean }) => {
  const [itemOpened, setItemOpened] = useState<string>();

  const items = useMemo(() => (nextStep ? NEXT_STEP_ITEMS : NO_NEXT_STEP_ITEMS), [nextStep]);

  return (
    <div className={styles.container}>
      <RichText center>
        <h2>Veelgestelde vragen</h2>
      </RichText>
      {items.map((item) => (
        <FaqItem
          key={item.question}
          item={item}
          active={itemOpened === item.question}
          onClick={() => (itemOpened === item.question ? setItemOpened(undefined) : setItemOpened(item.question))}
        />
      ))}
    </div>
  );
};

const FaqItem = ({ item, active, onClick }: FaqItemProperties) => {
  const onItemClick = useCallback(() => {
    onClick(item.question);
  }, [onClick, item.question]);

  const formattedAnswer = item.answer.replace(/<[^<>]+>/g, (match) => {
    const linkWithoutCarets = match.replace('<', '').replace('>', '');
    return `<a href='${linkWithoutCarets}' target='_blank' className=${styles.link}'>${linkWithoutCarets}</a>`;
  });

  const formattedAnswersWithLineBreaks = formattedAnswer.replace(/\n/g, '<br />');

  return (
    <button type="button" className={styles.item} onClick={onItemClick}>
      <HeroIcon icon={active ? 'MinusIcon' : 'PlusIcon'} />
      <div className={styles.heading}>
        <span className={styles.question}>{item.question}</span>
      </div>
      <p className={cx(styles.answer, { [styles.active]: active })}>
        {React.createElement('span', { dangerouslySetInnerHTML: { __html: formattedAnswersWithLineBreaks } })}
      </p>
    </button>
  );
};
