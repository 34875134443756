import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'components/Form';
import { Input } from 'components/Input';
import { Popup } from 'components/Popup';
import { RichText } from 'components/RichText';
import { sendIntake } from 'components/helpers/api';
import { captureException } from 'components/helpers/captureException';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

type FormInput = {
  email: string;
  firstName: string;
  surname: string;
};

const VALIDATION_OBJECT = object().shape({
  email: string().email('Voer een gelding e-mailadres in').required('E-mailadres is verplicht'),
  firstName: string().required('Voornaam is verplicht'),
  surname: string().required('Achternaam is verplicht'),
});

export const NewsLetterSubscriptionPopup = ({ onClose, intakeUuid }: { onClose: () => void; intakeUuid: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState<string>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: yupResolver(VALIDATION_OBJECT),
  });

  const router = useRouter();

  const onSubmit = useCallback(
    async (data: FormInput) => {
      try {
        setFormError(undefined);
        setIsLoading(true);
        if (!data.firstName || !data.surname) throw new Error('Voornaam en achternaam zijn verplicht');
        const response = await sendIntake(intakeUuid, data);
        router.replace(response.redirectUrl ?? 'https://juistwoz.nl/');
      } catch (error) {
        setFormError('Er is iets misgegaan, probeer het later opnieuw');
        captureException(error, { tags: { fn: 'NewsLetterSubscriptionPopup.onSubmit' } });
      } finally {
        setIsLoading(false);
      }
    },
    [intakeUuid, router]
  );
  return (
    <Popup onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)} error={formError} isSubmitting={isLoading}>
        <RichText>
          <h2>Laat je gegevens achter</h2>
        </RichText>
        <Input
          label="Voornaam"
          error={errors?.firstName?.message}
          placeholder="Voornaam"
          {...register('firstName', { required: true })}
        />
        <Input
          label="Achternaam"
          error={errors?.surname?.message}
          placeholder="Achternaam"
          {...register('surname', { required: true })}
        />
        <Input
          type="email"
          error={errors?.email?.message}
          label="E-mailadres"
          placeholder="E-mailadres"
          {...register('email', { required: true })}
        />
      </Form>
    </Popup>
  );
};
