import { AllFields } from 'components/helpers/types';

export function parseFormDataForSubmit(allData: Partial<AllFields> | undefined) {
  return {
    relationshipToProperty: allData?.livingSituation?.relationshipToProperty,
    houseType: allData?.livingSituation?.houseType,
    useSurface: allData?.charactaristics?.useSurface,
    bathroomAge: allData?.charactaristics?.bathroomAge,
    kitchenAge: allData?.charactaristics?.kitchenAge,
    houseDurability: allData?.quality?.houseDurability,
    houseMaintenanceLevel: allData?.quality?.houseMaintenanceLevel,
    isTransferredAfter2020: allData?.livingSituation?.isTransferredAfter2020 === 'yes',
    transferDate: allData?.livingSituation?.transferDate || undefined,
    finalRemarks: allData?.quality?.finalRemarks,
    transferPrice: allData?.livingSituation?.transferPrice
      ? Number.parseInt(allData?.livingSituation?.transferPrice, 10)
      : undefined,
    renovationPrice: allData?.livingSituation?.renovationPrice
      ? Number.parseInt(allData?.livingSituation?.renovationPrice, 10)
      : undefined,
    postcode: allData?.addressDetails?.postcode,
    house_number: allData?.addressDetails?.house_number?.toString(),
    house_number_addition: allData?.addressDetails?.house_number_addition,
    house_number_letter: allData?.addressDetails?.house_letter,
    salutation: allData?.personalData?.salutation,
    firstName: allData?.personalData?.firstName,
    surname: allData?.personalData?.surname,
    phoneNumber: allData?.personalData?.phone,
    email: allData?.personalData?.email,
  };
}
