import React, { useMemo } from 'react';
import styles from './Savings.module.css';
import { CalculateResponse } from 'components/helpers/types';

export const Savings = ({ savings }: { savings: CalculateResponse['range']['savings'] }) => {
  const roundedSavings = useMemo(
    () => ({
      ib: Math.round(savings.ib),
      ozb: Math.round(savings.ozb),
      water: Math.round(savings.water),
      total: Math.round(savings.ib) + Math.round(savings.ozb) + Math.round(savings.water),
    }),
    [savings]
  );
  return (
    <div className={styles.container}>
      <h2>Verwachte gemiddelde besparing per jaar</h2>
      <div className={styles.savingsLine}>
        <span>Inkomstenbelasting</span>
        <span>€ {roundedSavings.ib}</span>
      </div>
      <div className={styles.savingsLine}>
        <span>Onroerende zaakbelasting</span>
        <span>€ {roundedSavings.ozb}</span>
      </div>
      <div className={styles.savingsLine}>
        <span>Watersysteemheffing</span>
        <span>€ {Math.round(savings.water)}</span>
      </div>
      <div className={styles.seperation} />
      <div className={styles.totals}>
        <span>Totale besparing</span>
        <span>€ {roundedSavings.total}</span>
      </div>
    </div>
  );
};
