import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './Calculation.module.css';
import { Loader } from 'components/Loader';
import { HeroIcon } from 'components/HeroIcon';
import cx from 'classnames';
import { getProgress } from 'components/helpers/progress';
import { useRouter } from 'next/router';
import { useSteps } from 'context/StepsProvider';

const LOADING_STEPS = [
  { label: 'Ophalen van 100+ WOZ-datapunten voor jouw woning' },
  { label: 'Vergelijken van je woning met soortgelijke woningen in je buurt' },
  { label: 'Verwachte WOZ-waarde berekenen' },
];

export const Calculation = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  const { handleNextStep, submitData, submissionError, calculationSucceeded, setSubmissionError } = useSteps();

  const router = useRouter();

  const hasNavigated = useRef(false);

  const hasMounted = useRef(false);

  const progress = useMemo(() => getProgress()?.fieldValues, []);
  useEffect(() => {
    if (loadingProgress >= LOADING_STEPS.length && calculationSucceeded && !hasNavigated.current && progress) {
      handleNextStep();
      hasNavigated.current = true;
      return;
    }
  }, [handleNextStep, loadingProgress, router, calculationSucceeded, progress]);

  useEffect(() => {
    if (progress?.livingSituation?.relationshipToProperty === 'landlord') {
      setSubmissionError(
        'Je hebt bij de stap &quot;Woningsituatie&quot; aangegeven dat je een huurwoning hebt. Helaas kunnen wij voor huurwoningen geen berekening maken.'
      );
    }
  }, [progress?.livingSituation?.relationshipToProperty, setSubmissionError]);

  useEffect(() => {
    if (!hasMounted.current) {
      submitData();
      hasMounted.current = true;
    }
  }, [submitData]);

  useEffect(() => {
    const previousLoadingProgress = loadingProgress;

    const timeout = setTimeout(
      () => {
        setLoadingProgress((loadingProgress) => loadingProgress + 1);
      },
      700 + Math.floor(Math.random() * 800)
    );

    if (previousLoadingProgress > loadingProgress) {
      clearTimeout(timeout);
    }

    if (submissionError) {
      setLoadingProgress(-1);
    }

    if (submissionError && loadingProgress > LOADING_STEPS.length) {
      clearInterval(timeout);
      setLoadingProgress(-1);
    }

    return () => clearTimeout(timeout);
  }, [loadingProgress, submissionError, calculationSucceeded, submitData]);

  return (
    <ul className={styles.container}>
      {LOADING_STEPS.map((step, index) => {
        const finished = index < loadingProgress;
        if (loadingProgress === -1) {
          return;
        }
        return (
          <li key={step.label} className={cx(styles.step, { [styles.finished]: finished })}>
            {finished ? (
              <div className={styles.iconContainer}>
                <HeroIcon icon="CheckCircleIcon" />
              </div>
            ) : (
              <Loader />
            )}
            <span>{step.label}</span>
          </li>
        );
      })}
      {!!submissionError && <div className={styles.error}>{submissionError}</div>}
      {!submissionError && !calculationSucceeded && loadingProgress > LOADING_STEPS.length && (
        <p className={styles.longer}>Dit duurt wat langer dan normaal, een moment geduld...</p>
      )}
    </ul>
  );
};
