import { CalculateResponse } from 'components/helpers/types';
import styles from './WozRange.module.css';
import { RichText } from 'components/RichText';
import { Button } from 'components/Button';
import { useSteps } from 'context/StepsProvider';

import React, { Fragment, forwardRef, useCallback } from 'react';
import { VerticalMargin } from 'components/VerticalMargin';
import { trackEvent } from 'components/helpers/tracking';

export const WozRange = forwardRef<HTMLDivElement, { wozResult?: CalculateResponse['range'] }>(
  ({ wozResult }, reference) => {
    const { handleNextStep, resetScan } = useSteps();

    //when Betty blocks API gives an erorr, we get an empty array on wozResult
    const hasNoRange = !wozResult || (Array.isArray(wozResult) && !wozResult[0]);

    const handleStartIntake = useCallback(() => {
      handleNextStep();

      if (hasNoRange) return;

      trackEvent('startIntake');
    }, [handleNextStep, hasNoRange]);

    return (
      <div className={styles.container} ref={reference}>
        <RichText center>
          {hasNoRange ? (
            <Fragment>
              <h2>Jouw juiste WOZ-waarde</h2>
              <p>De verwachte WOZ-waarde ligt tussen:</p>
              <VerticalMargin size="small" />
            </Fragment>
          ) : (
            <Fragment>
              <h2>Jouw juiste WOZ-waarde</h2>
              <p>De verwachte WOZ-waarde ligt tussen:</p>
            </Fragment>
          )}
        </RichText>
        {hasNoRange ? undefined : (
          <div className={styles.range}>
            <div className={styles.horizontalAxis} />
            <div className={styles.rangeElements}>
              <div className={styles.rangeElement}>
                <p className={styles.amount}>€ {wozResult.lowRange.toLocaleString('nl-NL')}</p>
                <p className={styles.amountLabel}>Laagste WOZ</p>
              </div>
              <div className={styles.rangeElement}>
                <p className={styles.amount}>€ {wozResult.wozValue.toLocaleString('nl-NL')}</p>
                <p className={styles.amountLabel}>Verwachte WOZ</p>
              </div>
              <div className={styles.rangeElement}>
                <p className={styles.amount}>€ {wozResult.highRange.toLocaleString('nl-NL')}</p>
                <p className={styles.amountLabel}>Hoogste WOZ</p>
              </div>
            </div>
          </div>
        )}
        <RichText center>
          {!!wozResult?.previousWozValue && (
            <p className={styles.currentWoz}>Huidige WOZ-waarde: € {wozResult.previousWozValue.toLocaleString('nl')}</p>
          )}
        </RichText>
        <div className={styles.bottomContainer}>
          <div className={styles.buttonContainer}>
            <Button onClick={resetScan} theme="secondary">
              Start opnieuw
            </Button>
            <Button onClick={handleStartIntake} theme="cta">
              {hasNoRange ? 'Gegevens achterlaten' : 'Start mijn bezwaar'}
            </Button>
          </div>
          <p className={styles.disclaimer}>
            Onze werkzaamheden worden <strong>100% vergoed</strong> door de gemeente
          </p>
        </div>
      </div>
    );
  }
);

WozRange.displayName = 'WozRange';
