import styles from './AddressBlock.module.css';
import { Checkbox } from 'components/Checkbox';

interface AddressBlockProperties {
  address?: string;
}

export const AddressBlock = ({ address }: AddressBlockProperties) => (
  <div className={styles.container}>
    <div className={styles.addressWithTitle}>
      <div className={styles.checkbox}>
        <Checkbox externalChecked readOnly color="green" />
      </div>
      <h3 className={styles.title}>Jouw adres</h3>
      <p className={styles.address}>{address}</p>
    </div>
  </div>
);
