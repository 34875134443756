import { CalculateResponse } from 'components/helpers/types';
import Cookies from 'js-cookie';

const CALCULATION_DATA_COOKIE_KEY = 'calulationData';
const WOZ_RESULT_COOKIE_KEY = 'WOZ_RESULT';

export const setCookie = async (
  key: string,
  value: string | number | Record<string, unknown>,
  options?: Cookies.CookieAttributes
) => {
  if (typeof window === 'undefined') return;

  const valueToSave = typeof value === 'string' ? value : JSON.stringify(value);
  Cookies.set(key, valueToSave, options);
};

export const deleteCookie = (key: string) => {
  if (typeof window === 'undefined') return;

  Cookies.remove(key);
};

export const getCookie = <T>(key: string): T | undefined => {
  if (typeof window === 'undefined') return;

  const value = Cookies.get(key);

  return value ? JSON.parse(value) : undefined;
};

export const setCalulationData = (value: string) => {
  setCookie(CALCULATION_DATA_COOKIE_KEY, value);
};

export const setWozResult = (value: string) => {
  setCookie(WOZ_RESULT_COOKIE_KEY, value);
};

export const getWozResult = () => {
  const cookie = getCookie<{ uuid?: string; address: CalculateResponse['address']; range: CalculateResponse['range'] }>(
    WOZ_RESULT_COOKIE_KEY
  );
  if (cookie) {
    return cookie;
  }
};

export const deleteWozUuid = () => {
  deleteCookie(WOZ_RESULT_COOKIE_KEY);
};
