import { useQuery } from '@tanstack/react-query';
import {
  AddressValidationResponse,
  AddressValidationSubmit,
  CalculateResponse,
  CalculateSubmit,
  IntakeResponse,
  IntakeSubmit,
  SignatureSubmit,
} from 'components/helpers/types';

export interface PDOKResponseItem {
  bron: string;
  type: string;
  huis_nlt?: string;
  weergavenaam: string;
  id: string;
  postcode: string;
  huisnummer?: number;
  straatnaam: string;
  score: number;
  huisletter?: string;
  huisnummertoevoeging?: string;
}

interface PDOKResponseData {
  numFound: number;
  start: number;
  maxScore: number;
  numFoundExact: boolean;
  docs: PDOKResponseItem[];
}

export const useAddress = ({ searchString, enabled }: { searchString?: string | null; enabled?: boolean }) =>
  useQuery<{
    response: PDOKResponseData;
  }>({
    queryKey: ['useAddress', searchString],
    queryFn: () =>
      fetch(
        `https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?fl=id weergavenaam bron type straatnaam huisnummer huisletter huisnummertoevoeging huis_nlt postcode score&df=tekst&bq=type:adres^1&start=0&rows=10&sort=score desc,sortering asc,weergavenaam asc&wt=json&q=${searchString}`
      ).then((r) => r.json()),

    enabled,
    retry: false,
  });

export const validateAddress = async ({ postcode, house_number, addition, letter }: AddressValidationSubmit) =>
  fetchApi<AddressValidationResponse>(`/address/validate`, 'POST', { postcode, house_number, addition, letter });

export const calculateWoz = async (data: CalculateSubmit) => fetchApi<CalculateResponse>(`/woz_requests`, 'POST', data);

export const sendIntake = async (uuid: string, data: IntakeSubmit) =>
  fetchApi<IntakeResponse>(`/woz_requests/${uuid}/submit`, 'PUT', data);

export const sendSignature = async (data: SignatureSubmit) =>
  fetchApi<IntakeResponse>(`/woz_requests/${data.uuid}/signature`, 'PUT', data);

interface ResponseWithMaybeData<T> extends Response {
  data?: T;
}

const fetchApi = async <T>(endpoint: string, method: RequestInit['method'] = 'GET', body?: unknown) => {
  const response: ResponseWithMaybeData<T> = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${endpoint}`, {
    method,
    ...(body ? { body: JSON.stringify(body) } : {}),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const json: T = await response.json();

  if (response.ok) {
    return json;
  } else {
    response.data = json;
    throw new ApiError<T>(response);
  }
};

export class ApiError<T> extends Error {
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;
  public readonly body: T | undefined;

  constructor(response: ResponseWithMaybeData<T>) {
    super(`${response.status} ${response.statusText}`);

    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.data;
  }
}
