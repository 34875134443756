export const trackEvent = (name: string, parameters?: Record<string, string | number>) => {
  if (typeof window === 'undefined' || !window.gtag || !process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) {
    return;
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('trackEvent', name, parameters);
    return;
  }

  window.gtag('event', name, parameters);
};
