import React from 'react';
import styles from './KlantenVertellen.module.css';

export const KlantenVertellen = () => (
  <div className={styles.klantenvertellen}>
    <iframe
      title="Klantenvertellen"
      sandbox="allow-scripts"
      src="https://www.klantenvertellen.nl/retrieve-widget.html?color=white&allowTransparency=false&button=false&lang=nl&tenantId=99&locationId=1060481"
      width="400"
      height="222"
    />
  </div>
);
