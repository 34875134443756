import { AddressBlock } from 'components/AddressBlock';
import styles from './AddressDetails.module.css';
import { useFormContext } from 'react-hook-form';
import { AddressLookup } from 'components/AddressLookup';
import { STEPS } from 'components/constants/STEPS';
import { getErrorFromFormState } from 'components/helpers/getErrorFromFormState';

export const AddressDetails = () => {
  const { setValue, watch, formState } = useFormContext<any>();
  const addressFieldname = STEPS[0].subSteps[0].fields[0];

  const addressValue = watch(addressFieldname);

  return (
    <form>
      <div className={styles.container}>
        <AddressLookup
          addressError={getErrorFromFormState(formState, addressFieldname)}
          setValue={setValue}
          fieldName={addressFieldname}
          initialValue={addressValue}
        />
        {!!addressValue && addressValue?.length > 4 && <AddressBlock address={addressValue} />}
      </div>
    </form>
  );
};
