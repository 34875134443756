import { StepsProvider } from 'context/StepsProvider';
import './global.css';
import type { AppProps } from 'next/app';
import { Fragment, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Poppins } from 'next/font/google';
import Script from 'next/script';
import Head from 'next/head';
import { useRouter } from 'next/router';

const poppins = Poppins({
  subsets: ['latin'],
  weight: ['500', '600', '700'],
  display: 'swap',
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const client = useMemo(() => new QueryClient(), []);
  const { asPath } = useRouter();

  return (
    <Fragment>
      {!!process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && asPath !== '/embed' && (
        <Fragment>
          <Head>
            <noscript>
              <iframe
                sandbox=""
                title="Google tag manager"
                src={`https://sst.juistwoz.nl/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              />
            </noscript>
          </Head>

          <Script
            id="gtm-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://sst.juistwoz.nl/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
            }}
          />
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`}
          />
          <Script
            id="ga-datalayer"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
          `,
            }}
          />
        </Fragment>
      )}
      <QueryClientProvider client={client}>
        <StepsProvider>
          <style jsx global>{`
            html {
              font-family: ${poppins.style.fontFamily};
            }
          `}</style>
          <main>
            <Component {...pageProps} />
          </main>
        </StepsProvider>
      </QueryClientProvider>
    </Fragment>
  );
};

export default MyApp;
