import { HeroIcon } from 'components/HeroIcon';
import styles from './Usps.module.css';

const USP_ITEMS = [
  {
    title: 'Vergroot je slagingskans met het *eerste en grootste WOZ-bureau* van Nederland',
    icon: 'CheckBadgeIcon' as const,
  },
  {
    title: 'Al meer dan *150.000 klanten* geholpen met hun WOZ-waarde',
    icon: 'UsersIcon' as const,
  },
  {
    title: 'Jouw WOZ-dossier *altijd up-to-date en inzichtelijk*',
    icon: 'ClipboardDocumentListIcon' as const,
  },
];

export const Usps = () => (
  <ul className={styles.list}>
    {USP_ITEMS.map((item, index) => (
      <li key={index} className={styles.item}>
        <div className={styles.icon}>
          <HeroIcon icon={item.icon} />
        </div>
        <span
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: item.title.replaceAll(/\*(.*?)\*/g, '<strong>$1</strong>') }}
        />
      </li>
    ))}
  </ul>
);
