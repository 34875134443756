import { useFormContext } from 'react-hook-form';
import { RadioGroup } from 'components/RadioGroup';
import { STEPS } from 'components/constants/STEPS';
import { getErrorFromFormState } from 'components/helpers/getErrorFromFormState';

const OPTIONS = [
  {
    label: 'Beter',
    value: 'premium',
  },
  {
    label: 'Ongeveer hetzelfde',
    value: 'normal',
  },
  {
    label: 'Slechter',
    value: 'mediocre',
  },
];

export const Sustainability = () => {
  const { setValue, watch, formState } = useFormContext<any>();

  const houseDurabilityFieldName = STEPS[3].subSteps[0].fields[0];

  return (
    <RadioGroup
      error={getErrorFromFormState(formState, houseDurabilityFieldName)}
      name={houseDurabilityFieldName}
      options={OPTIONS}
      label="Hoe duurzaam is de woning vergeleken met andere woningen in jouw omgeving? (Denk bijvoorbeeld aan de isolatie of dubbel glas)"
      value={watch(houseDurabilityFieldName)}
      onChange={(event) => setValue(houseDurabilityFieldName, event.target.value)}
    />
  );
};
