import { useEffect, useState } from 'react';
import { getProgress } from 'components/helpers/progress';
import { AllFields } from 'components/helpers/types';
import { UseFormGetValues, UseFormReset } from 'react-hook-form';

export function useDefaultValues(reset: UseFormReset<AllFields>, getValues: UseFormGetValues<AllFields>) {
  const [defaultValues, setDefaultValues] = useState<Partial<AllFields>>();

  useEffect(() => {
    const allValues = getProgress()?.fieldValues;

    if (allValues) {
      setDefaultValues(allValues);
    }
  }, []);

  //We need this pattern in order for all values to be reset when the user returns to the application
  useEffect(() => {
    if (defaultValues) {
      reset?.(defaultValues);
    }
  }, [defaultValues, getValues, reset]);

  return defaultValues;
}
