import { captureException } from 'components/helpers/captureException';
import { getCookie, setCookie, deleteCookie } from 'components/helpers/cookies';
import { AllFields } from 'components/helpers/types';

export const PROGRESS_COOKIE_KEY = 'PREVICUS_WOZ_PROGRESS';

type Progress = { step: number; subStep: number; fieldValues?: Partial<AllFields> };

export const setProgress = (value: Progress) => {
  try {
    setCookie(PROGRESS_COOKIE_KEY, JSON.stringify(value));
  } catch (error) {
    captureException(error, { tags: { fn: 'setProgressCookie' } });
  }
};

export const getProgress = (): Progress | undefined => {
  try {
    const cookie = getCookie<Progress>(PROGRESS_COOKIE_KEY);
    return cookie;
  } catch (error) {
    captureException(error, { tags: { fn: 'getProgressCookie' } });
    return;
  }
};

export const deleteProgress = () => {
  try {
    deleteCookie(PROGRESS_COOKIE_KEY);
  } catch (error) {
    captureException(error, { tags: { fn: 'deleteProgress' } });
  }
};
