import styles from './Checkbox.module.css';
import { InputContainer } from 'components/InputContainer';
import { forwardRef, InputHTMLAttributes, useMemo } from 'react';
import cx from 'classnames';

interface CheckboxProperties extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  required?: boolean;
  hint?: string;
  error?: string;
  children?: React.ReactNode;
  externalChecked?: boolean;
  color?: 'orange' | 'green';
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProperties>(
  ({ id, label, required, hint, error, children, externalChecked, color = 'orange', ...rest }, reference) => {
    // eslint-disable-next-line unicorn/prefer-string-replace-all
    const elementId = useMemo(() => id || (label ? label.replaceAll(/\s+/g, '-').toLowerCase() : ''), [label, id]);

    return (
      <InputContainer error={error} required={required}>
        <div className={cx(styles.checkboxContainer, { [styles.green]: color === 'green' })}>
          <input type="checkbox" id={elementId} ref={reference} {...rest} checked={externalChecked} />
          <label htmlFor={elementId} className={styles.checkboxLabel}>
            <div className={styles.checkboxLabelContainer}>
              <div className={styles.checkboxLabelContainerInner}>
                {label}
                {children}
              </div>
              {hint ? <span className={styles.checkboxHint}>{hint}</span> : ''}
            </div>
          </label>
        </div>
      </InputContainer>
    );
  }
);

Checkbox.displayName = 'Checkbox';
