export function focusNextElement(mode?: 'forward' | 'backward') {
  //add all elements we want to include in our selection
  const focussableElements =
    'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

  const activeElement = document.activeElement as HTMLFormElement;

  if (!activeElement || !activeElement.form) return;

  const focussable = Array.prototype.filter.call(
    activeElement.form.querySelectorAll(focussableElements),
    function (element) {
      //check for visibility while always include the current activeElement
      return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement;
    }
  );

  const index = focussable.indexOf(document.activeElement);
  const nextElement = focussable[index + 1];
  const previousElement = focussable[index - 1];

  if (mode === 'forward') {
    nextElement ? nextElement.focus() : focussable[0].focus();
  }

  if (mode === 'backward') {
    previousElement ? previousElement.focus() : focussable.at(-1).focus();
  }
}
