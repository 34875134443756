import { useFormContext } from 'react-hook-form';
import { RadioGroup } from 'components/RadioGroup';
import { STEPS } from 'components/constants/STEPS';
import { getErrorFromFormState } from 'components/helpers/getErrorFromFormState';

const OPTIONS = [
  {
    label: 'Beter',
    value: 'premium',
  },
  {
    label: 'Ongeveer hetzelfde',
    value: 'normal',
  },
  {
    label: 'Slechter',
    value: 'mediocre',
  },
];

export const MaintananceLevel = () => {
  const { setValue, watch, formState } = useFormContext<any>();

  const houseMaintenanceLevelFieldName = STEPS[3].subSteps[1].fields[0];

  return (
    <RadioGroup
      error={getErrorFromFormState(formState, houseMaintenanceLevelFieldName)}
      name={houseMaintenanceLevelFieldName}
      options={OPTIONS}
      label="Hoe goed is de woning onderhouden vergeleken met andere woningen in jouw omgeving? (Denk bijvoorbeeld aan scheurvorming of slechte kozijnen)"
      value={watch(houseMaintenanceLevelFieldName)}
      onChange={(event) => setValue(houseMaintenanceLevelFieldName, event.target.value)}
    />
  );
};
